import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import TrackingLink from '@components/trackingLink';

import categories from '@config/categories';

import LlIcon from '@assets/navigation/wantimes_logo.svg';
import MenuIcon from '@assets/navigation/llicon-menu.svg';
import DashboardIcon from '@assets/navigation/llicon-dashboard.svg';
import CloseIcon from '@assets/navigation/llicon-remove-dark.svg';

import * as styles from './styles.module.scss';

const LINK_UTM = '?utm_source=wantimes&utm_medium=top&utm_campaign=hmb';

type Props = {
    isAmp?: boolean;
};

const Menu: React.FunctionComponent<Props> = ({ isAmp = false }) => {
    const { t } = useTranslation();
    return (
        <ul className={styles['amp_menu_ul']}>
            <li>
                {t('navigation.drawer.blog')}
                <ul>
                    <li>
                        <a href={`/category/food/${isAmp ? 'amp/' : ''}`}>{t('category.food')}</a>
                    </li>
                    <li>
                        <a href={`/category/dog-health/${isAmp ? 'amp/' : ''}`}>{t('category.dog-health')}</a>
                    </li>
                    <li>
                        <a href={`/category/training/${isAmp ? 'amp/' : ''}`}>{t('category.training')}</a>
                    </li>
                    <li>
                        <a href={`/category/lifestyle/${isAmp ? 'amp/' : ''}`}>{t('category.lifestyle')}</a>
                    </li>
                    <li>
                        <a href={`/category/leoandlea/${isAmp ? 'amp/' : ''}`}>{t('category.leoandlea')}</a>
                    </li>
                </ul>
            </li>
            <li>
                <a href={`https://leoandlea.com/${LINK_UTM}`}>
                    <span>{t('navigation.drawer.prem_food')}</span>
                    {t('navigation.drawer.about')}
                </a>
            </li>
            <li>
                <a href={`https://wantimes.leoandlea.com/media_coverage/${LINK_UTM}`}>
                    {t('navigation.drawer.publication')}
                </a>
            </li>
            <li>
                <a href={`https://leoandlea.com/create-dog-karte/${LINK_UTM}`}>{t('navigation.drawer.create')}</a>
            </li>
        </ul>
    );
};

const AmpDrawer: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles['amp_bar_nav']}>
                <div>
                    <div className={styles['amp_drawer_btn']} role="button" tabIndex={0} on="tap:sidebar1.toggle">
                        <MenuIcon />
                    </div>
                    <a href="/amp/">
                        <LlIcon />
                    </a>
                </div>
            </div>
            <amp-sidebar className={styles['amp_drawer']} id="sidebar1" layout="nodisplay" side="left">
                <div className={styles['amp_menu']}>
                    <header className={styles['amp_menu_header']}>
                        <div role="button" tabIndex={0} on="tap:sidebar1.toggle" className={styles['close_button']}>
                            <CloseIcon />
                        </div>

                        <a href={`https://leoandlea.com/customer/account/login/${LINK_UTM}`}>
                            {t('navigation.drawer.login')}
                            <DashboardIcon />
                        </a>
                    </header>

                    <Menu isAmp />
                </div>
            </amp-sidebar>
        </>
    );
};

const Drawer: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [drawerAnimation, setDrawerAnimation] = useState('');

    function handleClose() {
        setDrawerAnimation('closing');
        setTimeout(() => setDrawerAnimation(''), 300);
    }

    return (
        <>
            <button
                onClick={() => setDrawerAnimation('open')}
                className={styles['open_drawer_btn']}
                aria-label="Menu Left Align"
            >
                <MenuIcon />
            </button>
            <div className={classnames(styles['drawer'], styles[drawerAnimation])} id="nav">
                <div className={styles['drawer_bg']} aria-hidden="true" onClick={handleClose} />

                <div className={styles['menu']}>
                    <div>
                        <header>
                            <button type="button" onClick={handleClose}>
                                <CloseIcon />
                            </button>

                            <a href={`https://leoandlea.com/customer/account/login/${LINK_UTM}`}>
                                {t('navigation.drawer.login')}
                                <DashboardIcon />
                            </a>
                        </header>

                        <Menu />
                    </div>
                </div>
            </div>
        </>
    );
};

const CategoriesSlider: React.FunctionComponent<Props> = ({ isAmp = false }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['category_bar']}>
            <a href={isAmp ? '/category/all/amp/' : '/category/all/'} style={{ color: categories['leoandlea'].color }}>
                {t('category.all')}
            </a>
            {Object.keys(categories).map((category) => (
                <TrackingLink
                    href={`/category/${category}/${isAmp ? 'amp/' : ''}`}
                    style={{ color: categories[category]?.color }}
                    key={category}
                    tracking={{ event: 'Category Clicked', obj: { category_name: category } }}
                >
                    {t(`category.${category}`)}
                </TrackingLink>
            ))}
        </div>
    );
};

const Navigation: React.FunctionComponent<Props> = ({ isAmp = false }) => {
    const { t } = useTranslation();
    const [scroll, setScroll] = useState({ direction: 'up', position: 0 });

    const handleScroll = () => {
        setScroll(({ position }) => ({
            // if position is greater than window pageYOffset then is being scroller or if pageYOffset is 0 then inc nav height
            direction: window.pageYOffset <= 0 || position > window.pageYOffset ? 'up' : 'down',
            position: window.pageYOffset,
        }));
    };

    useEffect(() => {
        if (window) window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            {isAmp && <AmpDrawer />}

            <nav className={classnames(styles['navigation'], styles[scroll.direction])}>
                <div className={styles['nav_banner']}>
                    <TrackingLink href={`https://leoandlea.com/${LINK_UTM}`} tracking={{ event: 'Top Banner Clicked' }}>
                        {t('links.leoandlea_banner')}
                    </TrackingLink>
                </div>
                <div className={styles['nav_wrapper']}>
                    <div className={styles['nav_bar']}>
                        {!isAmp && (
                            <>
                                <Drawer />
                                <a href="/" aria-label="Home Center Align">
                                    <LlIcon />
                                </a>
                            </>
                        )}
                    </div>
                </div>
                <CategoriesSlider isAmp={isAmp} />
            </nav>
        </>
    );
};

export default Navigation;
