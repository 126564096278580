import React, { PropsWithChildren, useState } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

type Props = {
    className?: string;
    style?: { [key: string]: string | number };
    href: string;
    openNewPage?: boolean;
    tracking: {
        event: string;
        obj?: {
            article_category?: string;
            article_name?: string;
            uid?: string;
            category_name?: string;
        };
    };
};

const TrackingLink: React.FunctionComponent<PropsWithChildren<Props>> = ({
    className,
    style,
    href,
    openNewPage,
    tracking,
    children,
}) => {
    const [disabled, setDisabled] = useState(false);

    function track(e: any) {
        if (disabled) {
            e.preventDefault();
            return;
        }
        // window.analytics.track(tracking.event, { ...tracking.obj });
        trackCustomEvent({
            category: tracking?.event ?? 'Link',
            action: 'Link',
            label: tracking?.obj?.uid ?? tracking?.obj?.category_name,
        });
        setDisabled(true);
    }

    if (!tracking) {
        return (
            <a href={href} className={className}>
                {children}
            </a>
        );
    }

    if (openNewPage) {
        return (
            <a href={href} className={className} style={style} target="_blank" rel="noreferrer" onClick={track}>
                {children}
            </a>
        );
    }

    return (
        <a href={href} className={className} style={style} onClick={track}>
            {children}
        </a>
    );
};

export default TrackingLink;
