import * as variables from '@styles/variables.module.scss';

export default {
    food: {
        color: variables['categoryFoodColor'],
    },
    'dog-health': {
        color: variables['categoryDogHealthColor'],
    },
    training: {
        color: variables['categoryTrainingColor'],
    },
    lifestyle: {
        color: variables['categoryLifestyleColor'],
    },
    leoandlea: {
        color: variables['brandColor'],
    },
};
