import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './styles.module.scss';

// import FOOTER_BORDER from '@images/footer/footer-border.png';
import MEDAL_ICON from '@images/footer/medal.png';
import STAMP_ICON from '@images/footer/stamp.png';
import LlIcon from '@assets/navigation/ll-logo-dark.svg';

const LOGO_UTM = '?utm_source=wantimes&utm_medium=bottom&utm_campaign=logo';
const LINK_UTM = '?utm_source=wantimes&utm_medium=top&utm_campaign=hmb',
    // image options for amp-img
    ampImgOpts = {
        width: 88,
        height: 88,
        layout: 'responsive',
        style: { maxWidth: 88, maxHeight: 88, margin: '0 auto' },
    };

const Footer: React.FunctionComponent<{ isAmp?: boolean }> = ({ isAmp = false }) => {
    const { t } = useTranslation();
    return (
        <footer className={styles['footer']}>
            <div className={styles['footer__border']} />
            <div className={styles['footer__body']}>
                <a href={`https://leoandlea.com/${LOGO_UTM}`}>
                    <LlIcon />
                </a>
                <div className={styles['footer__links']}>
                    <a href={`https://leoandlea.com/${LINK_UTM}`}>{t('footer.about')}</a>
                    <a href={`https://leoandlea.com/create-dog-karte/${LINK_UTM}`}>{t('footer.create')}</a>
                    <a href={`https://leoandlea.com/company/${LINK_UTM}`}>{t('footer.company')}</a>
                </div>
                <div className={styles['footer__icons']}>
                    <div>
                        <a href={`https://start.leoandlea.com/v4/${LOGO_UTM}#reviews`}>
                            {isAmp ? (
                                <amp-img src={STAMP_ICON} alt="stamp icon" {...ampImgOpts} />
                            ) : (
                                <StaticImage
                                    src={'../../images/footer/stamp.png'}
                                    alt="stamp icon"
                                    formats={['auto', 'webp', 'avif']}
                                    placeholder="blurred"
                                    layout="fixed"
                                    width={72}
                                    height={88}
                                    loading="lazy"
                                    style={{ margin: '0 auto 5px' }}
                                />
                            )}
                            <span>{t('footer.stamp_note')}</span>
                        </a>
                    </div>
                    <div>
                        <a href={`https://start.leoandlea.com/v4/${LOGO_UTM}#reviews`}>
                            {isAmp ? (
                                <amp-img src={MEDAL_ICON} alt="medal icon" {...ampImgOpts} />
                            ) : (
                                <StaticImage
                                    src={'../../images/footer/medal.png'}
                                    alt="medal icon"
                                    formats={['auto', 'webp', 'avif']}
                                    placeholder="blurred"
                                    layout="fixed"
                                    width={88}
                                    height={88}
                                    loading="lazy"
                                    style={{ margin: '0 auto 5px' }}
                                />
                            )}
                            <span>{t('footer.medal_note')}</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
