import React, { PropsWithChildren, useEffect } from 'react';

import Nav from '@components/navigation';
import Footer from '@components/footer';
import InstagramGrid from '@components/instagramGrid';

import { withTrans } from '../../i18n/withTrans';
// import cookieParser from '@utils/cookieParser';

import * as styles from './styles.module.scss';

type Props = {
    location: {
        pathname: string;
    };
};

const Layout: React.FunctionComponent<PropsWithChildren<Props>> = ({ children, location }) => {
    useEffect(() => {
        // scroll top of page when navigating to new page
        window.scrollTo(0, 0);

        // set segment cookie ids as sentry user
        // const { ajs_anonymous_id = null, ajs_user_id = null } = cookieParser(document.cookie);
        // window.Sentry.setUser({ ajs_anonymous_id, ajs_user_id });
    }, []);

    const isAmp = () => {
        if (location.pathname.includes('/amp/')) return true;
        return false;
    };

    return (
        <div>
            <Nav isAmp={isAmp()} />
            <div className={styles['main']}>
                <div className={styles['content']}>{children}</div>
            </div>

            <InstagramGrid isAmp={isAmp()} />

            <Footer isAmp={isAmp()} />
        </div>
    );
};

export default withTrans(Layout);
