import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import INSTA_ICON from '@assets/sns/insta_color_icon.svg';
import LINE_ICON from '@assets/sns/line_color_icon.svg';
import FB_ICON from '@assets/sns/fb_color_icon.svg';

import * as styles from './styles.module.scss';

const InstagramGrid: React.FunctionComponent<{ isAmp?: boolean }> = ({ isAmp = false }) => (
    <div className={styles['instagram_wrapper']}>
        <span>Follow us on SNS</span>
        <div className={styles['icon_links']}>
            <a
                href="https://www.facebook.com/LeoandLeaJapan/"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook Link"
            >
                <FB_ICON />
            </a>
            <a href="http://instagram.com/leoandleajapan/" target="_blank" rel="noreferrer" aria-label="Instagram Link">
                <INSTA_ICON />
            </a>
            <a href="https://line.me/R/ti/p/%40aoq7369a" target="_blank" rel="noreferrer" aria-label="Line Link">
                <LINE_ICON />
            </a>
        </div>
        <div className={styles['instagram_grid']}>
            {/* <StaticQuery
                query={graphql`
                    query InstagramFeedQuery {
                        allImage(limit: 9) {
                            edges {
                                node {
                                    id
                                    permalink
                                    img_src
                                    image {
                                        childImageSharp {
                                            gatsbyImageData(
                                                placeholder: BLURRED
                                                width: 120
                                                height: 120
                                                formats: [AUTO, WEBP, AVIF]
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={({ allImage }) => {
                    return allImage.edges.map(({ node: { image, permalink, img_src } }) => (
                        <a href={permalink} key={permalink}>
                            {isAmp ? (
                                <amp-img src={img_src} alt={permalink} layout="responsive" width={100} height={100} />
                            ) : (
                                <GatsbyImage image={image?.childImageSharp?.gatsbyImageData} alt={permalink} />
                            )}
                        </a>
                    ));
                }}
            /> */}
        </div>
    </div>
);

export default InstagramGrid;
