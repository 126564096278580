// extracted by mini-css-extract-plugin
export var brandColor = "#846d3d";
export var mainColor = "#2f4758";
export var subColor = "#c0d1dd";
export var lightColor = "#f4f6f8";
export var greyColor = "#707070";
export var darkColor = "#363636";
export var subscriptionColor = "#65c79b";
export var orderColor = "#298df3";
export var errorColor = "#fd3894";
export var frontlineColor = "#e08912";
export var categoryFoodColor = "#e08912";
export var categoryDogHealthColor = "#298df3";
export var categoryTrainingColor = "#65c79b";
export var categoryLifestyleColor = "#fd3188";
export var navigation = "styles-module--navigation--HfnYr";
export var down = "styles-module--down--3xK3S";
export var nav_wrapper = "styles-module--nav_wrapper--1tdP7";
export var nav_bar = "styles-module--nav_bar--1-O_w";
export var nav_banner = "styles-module--nav_banner--acmeE";
export var category_bar = "styles-module--category_bar--3RnW3";
export var amp_drawer_btn = "styles-module--amp_drawer_btn--3J0Qd";
export var open_drawer_btn = "styles-module--open_drawer_btn--2BilX";
export var drawer = "styles-module--drawer--1FEqi";
export var open = "styles-module--open--14ljn";
export var drawer_bg = "styles-module--drawer_bg--1AV6U";
export var fadeInBackground = "styles-module--fade-in-background--3GBpT";
export var menu = "styles-module--menu--2hxIT";
export var closing = "styles-module--closing--1UWCF";
export var fadeOutBackground = "styles-module--fade-out-background--2vpGV";
export var amp_bar_nav = "styles-module--amp_bar_nav--1mPW8";
export var amp_menu = "styles-module--amp_menu--mJR9L";
export var close_button = "styles-module--close_button--1zmCB";